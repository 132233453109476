// avatar
.avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    overflow: hidden;

    img {
        vertical-align: top;
    }

    &-md {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
    }

    &-xl {
        width: 120px;
        height: 120px;
    }

    &-lg {
        width: 70px;
        height: 70px;
        line-height: 65px;
        text-align: center;
        overflow: hidden;
        position: relative;
        // border: 5px solid $light;

        i {
            color: $primary;
            font-size: 1.5rem;
        }
    }
}

.separator {
    display: inline-block;
    text-align: center;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray-light;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.bg-image {
    height: 5rem;
}

.fa-ul {
    margin-left: 1.25rem !important;
}

nav {
    ol>li {
        &.breadcrumb-item {
            font-size: large;
        }

        a.active {
            color: $primary !important;
        }
    }
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      20: 0.2,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
        ))
);