.form-outline .form-control {
    background: $gray-light;
}

.select-input.form-control[readonly]:not([disabled]) {
    background-color: $gray-light;
}

input[type=tel],
.form-control {
    height: $input-height;
    border-color: $input-border-color;
    &:disabled {
        color: $secondary;
        background-color: $gray-light;
        
        &:hover {
            cursor: not-allowed;
        }
    }

    &:active,
    &:focus {
        // box-shadow: inset 0px 0px 0px 1px $input-focus-border-color;
        box-shadow: none !important;
        border-color: $input-focus-border-color;
    }

}

// input group style
.input-group {
    &-lg {
        &:focus {
            transition: $input-group-focus-transition;
            border: 1px solid $input-group-focus-border-color;
            outline: 0;
        }

        &>.form-control {
            margin-bottom: 0;

            &:focus {
                border-color: $primary;
                // box-shadow: none;
            }
        }
    }

    &>.form-control {
        margin-bottom: 0;
        border: none;
        &:focus {
            border: none;
        }
    }

    &-text {
        border: none;
        background: $input-bg;
    }
}

// form outline
.form-outline {
    .form-control {
        background: $input-bg;

        ~.form-notch {
            div {
                border: 0;
            }
        }
    }
}


// select
mdb-select {
    border-radius: 4px;
}
.select ~ .form-notch div{
    border: 0;
}
.select.focused{
    & ~ .form-notch .form-notch-leading,
    & ~ .form-notch .form-notch-middle,
    & ~ .form-notch .form-notch-trailing{
       border-color: $gray;
       border-width: 1px;
      }
}
.select-outline{
    .form-control{
        background-color: $white !important;
    }
    .select ~ .form-notch {
        div{
            border-style: solid;
            border-width: 1px;
            border-color:  $gray-light;
           &.form-notch-leading{
                border-right-width: 0;
            }
            &.form-notch-middle{
               border-left: none;
               border-right: none;
            }
            &.form-notch-trailing{
                border-left: none;
             }
    }
    }
}
.select-input-clear {
    .select-input {
        color: $white;

        &.form-control {
            &[readonly]:not([disabled]) {
                color: $white;
                background-color: $input-bg-clear;
            }

            &::-webkit-input-placeholder {
                /* Edge */
                color: $white;
            }

            &::-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $white;
            }

            &::placeholder {
                color: $white;
            }

        }

    }
}

.select-input {
    &.form-control {
        &[readonly]:not([disabled]) {
            background-color: $input-bg;
        }
    }

    .bg-white>& {
        background-color: $white !important;
    }

    .bg-primary>& {
        color: $white;
        background-color: $primary !important;

        &+.select-arrow {
            color: $white;
        }
    }

    .bg-info>& {
        color: $white;
        background-color: $info !important;

        &+.select-arrow {
            color: $white;
        }
    }

    .bg-success>& {
        color: $white;
        background-color: $success !important;

        &+.select-arrow {
            color: $white;
        }
    }

    .bg-danger>& {
        color: $white;
        background-color: $danger !important;

        &+.select-arrow {
            color: $white;
        }
    }

    .bg-warning>& {
        color: $white;
        background-color: $warning !important;

        &+.select-arrow {
            color: $white;
        }
    }

    .select-sm & {
        height: $input-height-sm;
        font-size: $input-font-size-sm;

        &+.select-arrow {
            top: 5px;
        }
    }
}

.is-invalid {
    border: 1px solid $danger !important;
}