html, body {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

@import 'components/navbar';
@import 'components/sidebar';
@import 'components/select';
@import 'components/button';
@import 'components/form';
@import 'components/modal';
@import 'components/layout';
@import 'components/table';
@import 'components/utility';
@import 'components/code-input';