.sidenav {
  color: #1E1E1E;
  overflow: visible !important;
  position: fixed;
  left: 0;
  // top: 75px;
  width: 250px;
  z-index: 1040;

  &-item {
    color: $white;
  }
}

.sidenav-link.active {
  background-color: $primary !important;
  font-weight: $font-weight-normal !important;
}

.brand {
  width: 100%;
  height: $navbar-height;
 
  // box-shadow: $navbar-box-shadow;
  &.collapse{
    .logo-full{
      width: 114px; //hide logo when sidenav collapsed
      height: 55px;
      transform: translateX(-200px);
      transition: all ease .3s;
    }
    .logo-icon{
      transform: translateX(-126px);
      transition: all ease .3s;
    }
  }
  .logo-full{
    width: 114px; //hide logo when sidenav collapsed
    height: 55px;
    transform: translateX(0);
    transition: all ease .3s;
  }
  .logo-icon{
    max-width: 1.5rem;
    transform: translateX(-200px); //hide logo when sidenav not collapsed
    transition: all ease .3s;
  }
}

.app-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidenav-menu {
  flex: 1;
}

.sidenav-link {
  padding: 1rem 1.5rem;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  display: flex;
  align-items: center;
  // color: $white;
  border-right: 3px solid transparent;

  &:hover {
    .sidenav-primary & {
      background: rgba($primary, 0.1);
    }
  }

  &.active {
    color: $white;
    @extend .fw-bold;
    background: rgba($white, 0.1);
    border-color: $primary;
  }
}

mdb-sidenav-content {
  >div {
    padding-left: 0px;
  }
}